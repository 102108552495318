import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { Box } from "@chakra-ui/react";
import paulosMainLogo from "./paulos.png";
import paulosArcade from "./paulosArcade.png";
import paulosNFTs from "./paulosNFTs.png";
import sound from "./paulosTheme.mp3";
import "./App.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import QuestModal from "./components/QuestModal";

function App() {
  const [arcadeType, setArcadeType] = useState("");
  const [audio] = useState(new Audio(sound));
  const playAudio = () => {
    audio.play();
    audio.volume = 0.5;
  };
  const handleGamesScroll = () => {
    const element = document.getElementById("games");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleNFTsScroll = () => {
    const element = document.getElementById("nfts");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const alchemistLink = (
    <>
      <span>built by an </span>
      <a href="https://alchemist.wtf">Alchemist </a>
      <a href="https://twitter.com/Groovybeing">@Groovybeing</a>
    </>
  );
  return (
    <div className="App">
      <header
        className="App-header"
        onMouseEnter={() => playAudio()}
        onTouchStart={() => playAudio()}
      >
        <div className="stars" />
        <div className="twinkling" />
        <div className="clouds" />
        <img src={paulosMainLogo} className="paulos-logo" alt="logo" />
      </header>
      <div className="text-logo">
        <div onClick={() => handleGamesScroll()}>The Arcade</div>
        <div onClick={() => handleNFTsScroll()}>NFT Drop (Live)</div>
      </div>
      <div className="site-sections">
        <div className="games" id="games">
          <div className="play-on-desktop">
            Visit this site on Desktop to play the Arcade.
          </div>
          <img src={paulosArcade} className="games-logo" alt="logo" />
          <div className="games-heading">
            <div>The Arcade</div>
          </div>
          <div className="game-options">
            <div
              onClick={() => {
                setArcadeType("quest");
                audio.pause();
              }}
            >
              Story Mode
            </div>
            <div
              onClick={() => {
                setArcadeType("high");
                audio.pause();
              }}
            >
              High Score Games
            </div>
          </div>
        </div>
        <div className="nfts-mint" id="nfts">
          <div className="nfts-mint-heading">
            <Box
              minWidth="85px"
              as="div"
              display="inline-block"
              textAlign="center"
              paddingBottom={"15px"}
            >
              <div>NFT Drop Is Live</div>
            </Box>
            <iframe
              style={{ zIndex: 10 }}
              src="https://fjordnfts.com/widget/mint/mainnet/0x589e8DAF1de6b0F78f8a2DF817F7dBAdd8FF2dcD?padding=20px"
              height={"220px"}
              width={"390px"}
            />
          </div>
        </div>
        <div className="nfts">
          <img src={paulosNFTs} className="nfts-logo" alt="logo" />
        </div>
        <div className="footer" id="footer" style={{ height: "60px" }}>
          <div style={{ marginBottom: "5px" }}>
            updates <a href="https://twitter.com/paulosquest">@paulosquest</a>
          </div>
          <div>{alchemistLink}</div>
        </div>
      </div>
      <QuestModal
        isQuest={arcadeType === "quest"}
        isHighScore={arcadeType === "high"}
        onClose={() => {
          setArcadeType("");
          audio.play();
        }}
      />
    </div>
  );
}

export default App;
