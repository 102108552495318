import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/modal";
import { Spinner } from "@chakra-ui/spinner";
import {
  AspectRatio,
  Box,
  HStack,
  Kbd,
  StackDivider,
  Text,
} from "@chakra-ui/react";

type Props = {
  isHighScore: boolean;
  isQuest: boolean;
  onClose: () => void;
};

const QuestModal: React.FC<Props> = ({ onClose, isHighScore, isQuest }) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Modal
      isOpen={isHighScore || isQuest}
      onClose={onClose}
      size="full"
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay zIndex="10" backgroundColor={"black"} borderColor="black" />
      <ModalContent
        borderRadius={0}
        bg="black"
        backdropFilter="blur(25px)"
        zIndex="10"
      >
        <ModalCloseButton
          backgroundColor={"black"}
          borderColor="black"
          color="white"
        />
        <ModalBody
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          zIndex="10"
        >
          {isLoading && (
            <Spinner
              position="absolute"
              left={0}
              right={0}
              margin="0 auto"
              zIndex={-1}
            />
          )}
          <AspectRatio
            w="100%"
            maxH="700px"
            ratio={isHighScore ? 1280 / 720 : 1366 / 768}
          >
            <iframe
              onLoad={() => setIsLoading(false)}
              src={
                isHighScore
                  ? "https://paulos-quest-highscore.netlify.app"
                  : "https://paulos-quest.netlify.app"
              }
              allowFullScreen
            />
          </AspectRatio>
          {!isHighScore && !isLoading && (
            <Box mt={8} color="white">
              <HStack spacing={15} divider={<StackDivider />}>
                <HStack>
                  <Kbd fontSize="2xl">{"←"}</Kbd>
                  <Text>Move left</Text>
                </HStack>
                <HStack>
                  <Kbd fontSize="2xl">{"→"}</Kbd>
                  <Text>Move right</Text>
                </HStack>
                <HStack>
                  <Kbd fontSize="2xl">{"x"}</Kbd>
                  <Text>Shoot</Text>
                </HStack>
                <HStack>
                  <Kbd fontSize="2xl">{"space"}</Kbd>
                  <Text>Jump</Text>
                </HStack>
                <HStack>
                  <Kbd fontSize="2xl">{"m"}</Kbd>
                  <Text>Menu</Text>
                </HStack>
              </HStack>
            </Box>
          )}
          {isHighScore && !isLoading && (
            <Box mt={8} color="white">
              <HStack spacing={15} divider={<StackDivider />}>
                <HStack>
                  <Kbd>{"←"}</Kbd>
                  <Text>Left</Text>
                </HStack>
                <HStack>
                  <Kbd>{"→"}</Kbd>
                  <Text>Right</Text>
                </HStack>
                <HStack>
                  <Kbd>{"↑"}</Kbd>
                  <Text>Up</Text>
                </HStack>
                <HStack>
                  <Kbd>{"↓"}</Kbd>
                  <Text>Down</Text>
                </HStack>
                <HStack>
                  <Kbd>{"x"}</Kbd>
                  <Text>X box/Shoot</Text>
                </HStack>
                <HStack>
                  <Kbd>{"space"}</Kbd>
                  <Text>Jump</Text>
                </HStack>
                <HStack>
                  <Kbd>{"z"}</Kbd>
                  <Text>Switch weapon</Text>
                </HStack>
                <HStack>
                  <Kbd>{"d"}</Kbd>
                  <Text>Dance</Text>
                </HStack>
              </HStack>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default QuestModal;
